import { Button, Modal } from 'reactstrap';
import styled from 'styled-components';
import { theme } from '../theme';

export const Required = styled.span`
  color: ${theme.colors.primary["500"]};
`;

export const CustomModal = styled(Modal)`
  max-width: 440px;
  .modal-content {
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.10);
    border-radius: 12px !important;
  };

  .modal-header { 
    padding: 16px;
  }
`;

export const CustomButton = styled(Button)`
  border-radius: 6px;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  border: none;
  padding: 6px 12px;

  ${({ primary }) => primary ? `
    color: ${theme.colors.white} !important;
    background: ${theme.colors.primary["500"]} !important;
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    
    &:hover {
      background: ${theme.colors.primary["600"]} !important;
      box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    };

    &:active {
      background: ${theme.colors.primary["500"]} !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    };
  ` : `
    color: ${theme.colors.grey["700"]} !important;
    background: ${theme.colors.white} !important;
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    
    &:hover {
      color: ${theme.colors.grey["900"]} !important;
      box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    }

    &:active {
      color: ${theme.colors.grey["700"]} !important;
      box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10) !important;
    }
  `}
`;