/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';
import { Button, Badge } from 'reactstrap';
import { MdCancel } from 'react-icons/md';
import { FaAngleRight } from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';
import moment from 'moment';
import Datetime from 'react-datetime';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Link, withRouter } from 'react-router-dom';

import { titledHeader } from './table';
import CleanButton from '../components/cleanButton';
import TaskState from '../components/taskState';
import LocalTimeComponent from '../components/localTimeComponent';

const transformFilters = (filters) => {
  let filtersArray = [];
  Object.keys(filters).forEach(key => {
    if (key.endsWith("_from")) {
      //Filter type: date range - from
      const filterName = key.split("_from")[0];
      let existingElement = filtersArray.find(elem => elem.id === filterName);
      existingElement = existingElement || (filtersArray[filtersArray.length] = { id: filterName, value: {} });
      existingElement.value.from = moment(decodeURIComponent(filters[key]));
    }
    else if (key.endsWith("_to")) {
      //Filter type: date range - to
      const filterName = key.split("_to")[0];
      let existingElement = filtersArray.find(elem => elem.id === filterName);
      existingElement = existingElement || (filtersArray[filtersArray.length] = { id: filterName, value: {} });
      existingElement.value.to = moment(decodeURIComponent(filters[key]));
    }
    else {
      //Filter type: simple field or attribute - string
      filtersArray.push({ id: key, value: filters[key] });
    }
  });
  return filtersArray;
};

export const queryToSettings = (queryString) => {
  let { page, page_size, ordering, details, ...filters } = qs.parse(queryString.replace('?', ''));
  return {
    filtered: transformFilters(filters),
    sorted: ordering && [ {
      id: ordering.replace('-', ''),
      desc: ordering.startsWith('-')
    } ],
    details,
    page: parseInt(page, 10) - 1,
    pageSize: parseInt(page_size, 10),
  };
};

export const formatDate = (stringDate, relative, uniqueID, format = "DD.MM.Y HH:mm:ss.SSS") => {
  if (stringDate) {
    // stringDate is not empty
    const date = moment(stringDate);
    const absoluteDate = date.format(format);
    if (relative) {
      return (<span title={absoluteDate}>{date.fromNow()}</span>);
    }
    else {
      return (<LocalTimeComponent id={'id_' + uniqueID}>{titledHeader(absoluteDate)}</LocalTimeComponent>);
    }
  }
  else {
    // stringDate is empty
    return null;
  }
};

export const timeRangeFilter = (t, columnName) => {
  const Subcomponent = ({ filter, onChange }) => (
    <div className='pl-2 pr-2'>
      <hr />
      <div className='pl-3 pr-3'>
        <p className="filter-header">
          {t(columnName + '_column')}
          {filter && filter.value && (filter.value.from || filter.value.to) && (<CleanButton callback={event => onChange({ from: "", to: "" })} />)}
        </p>
        <GoCalendar className="calendar-input-icon" />
        <Datetime
          value={filter && filter.value ? filter.value.from : ""}
          onChange={value => onChange({
            from: value,
            to: filter && filter.value && filter.value.to ? filter.value.to : ""
          })}
          inputProps={{ placeholder: t('from') }}
          locale={t("language_code_for_moment")}
        />
        <GoCalendar className="calendar-input-icon" />
        <Datetime
          value={filter && filter.value ? filter.value.to : ""}
          onChange={value => onChange({
            from: filter && filter.value && filter.value.from ? filter.value.from : "",
            to: value
          })}
          inputProps={{ placeholder: t('to') }}
          locale={t("language_code_for_moment")}
        />
      </div>
    </div>
  );
  Subcomponent.propTypes = {
    filter: PropTypes.object,
    onChange: PropTypes.func,
  };

  return Subcomponent;
};

const facetCheckboxChanged = (filterValues, onChange, targetValue, checked, grant) => (event) => {
  if (grant) {
    const valueIndex = filterValues.indexOf(targetValue);
    if (valueIndex > -1 && checked) {
      //Removing key from values
      filterValues.splice(valueIndex, 1);
    }
    else if (valueIndex < 0 && !checked) {
      //Adding key to values
      filterValues.push(targetValue);
    }
    onChange(filterValues);
  }
};


export const facetFilter = (t, facets, path, itemClassName = '') => {
  const Subcomponent = ({ column, filter = { value: [] }, onChange, history, location }) => {
    const facet = _.get(facets, path, undefined);
    const facetKeys = facet ? Object.keys(facet) : [];
    let filterValues = Array.isArray(filter.value) ? filter.value : [ filter.value ];
    const isGrant = (typeof column.Header === 'function' ? column.Header() : column.Header).props.title === "Grant";
    const grantDefault = qs.parse(location.search, { ignoreQueryPrefix: true }).grant_default;

    // Parses query and replaces old grants data to new one
    const allGrantRedirect = (event) => {
      if (grantDefault !== undefined) {
        const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
        const parsedSearchKeys = Object.keys(parsedSearch);
        let search = '';
        // Only one grant selected
        if (filterValues.length === 1) {
          for (let i = 0; i < parsedSearchKeys.length; i++) {
            if (parsedSearchKeys[i] !== 'grant' && parsedSearchKeys[i] !== 'grant_default') {
              search += `${parsedSearchKeys[i]}=${parsedSearch[parsedSearchKeys[i]]}&`;
            }
          }
          history.push({
            pathname: location.pathname,
            search: `?${search}grant_default=${grantDefault}&grant=all`
          });
        } 
        // More than one grant selected
        else {
          for (let i = 0; i < parsedSearchKeys.length; i++) {
            const tempArray = parsedSearch[parsedSearchKeys[i]];
            if (parsedSearchKeys[i] === 'grant') {
              for (let j = 0; j < tempArray.length; j++) {
                if (tempArray[j] !== event.target.name) {
                  search += `${parsedSearchKeys[i]}=${tempArray[j]}&`;
                }
              }
            } 
            else {
              search += `${parsedSearchKeys[i]}=${tempArray}&`;
            }
          }
          history.push({
            pathname: location.pathname,
            search: `?${search}`
          });
        }
      }
    };
    
    return (
      <div className='pl-2 pr-2'>
        <hr />
        <div className='pl-3 pr-3'>
          <p className="filter-header">
            {typeof column.Header === 'function' ? column.Header() : column.Header}
            {filterValues.length ? (<CleanButton callback={event => onChange("")} />) : null}
          </p>
          {facetKeys.map(key => {
            const checked = filterValues.includes(key);
            return (
              <div key={key}>
                <label className="flex-vertical-center" onClick={event => {event.stopPropagation(); isGrant && allGrantRedirect(event);}}>
                  <input 
                    name={key}
                    type="checkbox" 
                    onChange={facetCheckboxChanged(filterValues.slice(), onChange, key, checked, (!isGrant || (isGrant && !checked)))} 
                    checked={checked}
                  />
                  <span className={`flex-vertical-center ${itemClassName}`}>
                    {
                      column.id === 'major_state' ?
                        <TaskState state={key} /> 
                        : 
                        <span className="filter-text" title={key}>{key}</span>
                    }
                    <Badge color="light" pill className="ml-2">{facet[key]}</Badge>
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  Subcomponent.propTypes = {
    column: PropTypes.object,
    filter: PropTypes.object,
    onChange: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object
  };

  return withRouter(Subcomponent);
};

export const generateColumns = (
  t,
  toggleableColumns,
  toggleStateField,
  idArray,
  openCancelingModal,
  facets,
  customColumnsSet = {},
) => {
  const columnsSet = {
    "id": {
      Header: titledHeader(t("id_column")),
      accessor: "id",
      Cell: row => titledHeader(row.value)
    }
    ,
    "name": {
      Header: titledHeader(t("name_column")),
      accessor: "note",
      Cell: row => titledHeader(row.value)
    }
    ,
    "user": {
      Header: titledHeader(t("user_column")),
      accessor: "user",
      Cell: row => titledHeader(row.value)
    }
    ,
    "state": {
      Header: titledHeader(t("state_column")),
      accessor: "major_state",
      Filter: facetFilter(t, facets, "major_state"),
      Cell: row => <TaskState state={row.value} />
    }
    ,
    "queue": {
      Header: titledHeader(t("queue_column")),
      accessor: "queue",
      Filter: facetFilter(t, facets, "queue", 'queue-item'),
      Cell: row => titledHeader(row.value)
    }
    ,
    "template": {
      Header: titledHeader(t("template_column")),
      accessor: "template_name",
      Filter: facetFilter(t, facets, "template_name"),
      Cell: row => titledHeader(row.value)
    }
    ,
    "grant": {
      Header: titledHeader(t('grant_column')),
      accessor: "grant",
      Filter: facetFilter(t, facets, "grant"),
      Cell: row => titledHeader(row.value)
    }
    ,
    "submit_time": {
      Header: () => (
        <span title={t("submit_time_column")}>
          {t("submit_time_column")}
          <br />
          <label onClick={event => event.stopPropagation()}>
            <input className='mr-2' type="checkbox" onChange={toggleStateField("submit_time")} checked={toggleableColumns.get("submit_time") || false} />
            <span>{t("relative_time")}</span>
          </label>
        </span>
      ),
      accessor: "submit_time",
      Filter: timeRangeFilter(t, "submit_time"),
      Cell: row => formatDate(row.value, toggleableColumns.get("submit_time"), 'submit-time-' + row.index),
      headerClassName: 'submit-time-header'
    }
    ,
    "updated_time": {
      Header: () => (
        <span title={t("updated_time_column")}>
          {t("updated_time_column")}
          <br />
          <label onClick={event => event.stopPropagation()}>
            <input className='mr-2' type="checkbox" onChange={toggleStateField("updated_time")} checked={toggleableColumns.get("updated_time") || false} />
            <span>{t("relative_time")}</span>
          </label>
        </span>
      ),
      accessor: "updated_time",
      Filter: timeRangeFilter(t, "updated_time"),
      Cell: row => formatDate(row.value, toggleableColumns.get("updated_time"), 'updated-time' + row.index),
      headerClassName: 'modify-time-header'
    }
    ,
    "nodes": {
      Header: titledHeader(t("nodes_column")),
      accessor: "nodes"
    }
    ,
    "actions": {
      Header: titledHeader(t("actions_column")),
      Filter: () => null,
      Cell: row => (
        <div>
          <Button
            outline
            color="secondary"
            className="task-cancel-button"
            onClick={() => { openCancelingModal([ row.original.id ]); }}
            title={t('cancel_job')}
          >
            <MdCancel />
          </Button>
          <Link
            className='task-details-link text-secondary'
            to={ `/ui/jobs/${row.original.id}/` }
            title={t('details')}
          >
            <FaAngleRight className="mt-1"/>
          </Link>
        </div>
      ),
      sortable: false,
    }
  };
  const allColumnsSet = { ...columnsSet, ...customColumnsSet };
  let columns = [];
  idArray.map(column => {
    if (column.get("visibility"))
      columns.push(allColumnsSet[column.get("key")]);
    return null;
  });

  return columns;
};

export const selectInputComponent = props => {
  return (
    <label className="text-transparent" onClick={e => {
      e.stopPropagation();
    }}>
      <input
        data-testid={props.id + '-checkbox'}
        type={props.selectType || 'checkbox'}
        checked={props.checked}
        onChange={(e) => {
          props.onClick(props.id, e.shiftKey, props.row);
        }}
      />
      <span />
    </label>
  );
};

selectInputComponent.propTypes = {
  selectType: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  id: PropTypes.string,
  row: PropTypes.object,
};
