import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from '@axios';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { __env } from '../../envloader';
import backendTranslation from '../utils/backendTranslation';

const SelectDefaultQuery = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [ defaultGrants, setDefaultGrants ] = useState();
  const [ defaultGrantsErr, setDefaultGrantsErr ] = useState();

  // Parses query
  const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
  const grantDefaultQuery = parsedSearch.grant_default;
  const grantQuery = parsedSearch.grant;

  // Parses query and replaces old grants data to new one
  const updateDefaultQuery = (value) => {
    const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    delete parsedSearch.grant_default;
    delete parsedSearch.grant;
    const search = qs.stringify(parsedSearch);
    const grantParams = location.pathname === '/ui/tasks'
      ? `&grant_default=${value}&grant=${value}` 
      : `&grant_default=${value}`;

    history.push({
      pathname: location.pathname,
      search: `?${search}${value ? grantParams : ""}`
    });
  };

  useEffect(() => {
    axios.get('user/').then(
      response => setDefaultGrants(response.data['user-info'].grants)
    ).catch(err => setDefaultGrantsErr(true));
  }, []);

  useEffect(() => {
    // Adds grant query to the link 
    let search = location.search;

    if (grantDefaultQuery !== undefined && grantQuery === undefined && location.pathname === '/ui/tasks') {
      search += `&grant=${grantDefaultQuery}`;
      history.push({
        pathname: location.pathname,
        search: search
      });
    }
    
  }, [ location, grantDefaultQuery, grantQuery, history ]);

  const theme_grant_label = __env.THEME?.topbar?.grant_label;
  const theme_every_grant = __env.THEME?.topbar?.every_grant;

  return (
    <>
      {
        (!defaultGrantsErr && defaultGrants) &&
        (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret className="center">
              {theme_grant_label ? backendTranslation(theme_grant_label) : t('topbar.grant_label')}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => updateDefaultQuery("")} active={!grantDefaultQuery}>
                {theme_every_grant ? backendTranslation(theme_every_grant) : t('topbar.every_grant')}
              </DropdownItem>
              {
                defaultGrants.map(ele => (
                  <DropdownItem key={ele} onClick={() => updateDefaultQuery(ele)} active={grantDefaultQuery === ele}>
                    {ele}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    </>
  );

};

export default SelectDefaultQuery;