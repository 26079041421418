import styled, { css, keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { theme } from '../theme';
import { NormalColumn, NormalRow } from './GlobalStyles';

export const Breadcrumbs = styled.div`
    height: 48px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.colors.primary[500]};

    & > :nth-child(3) {
      padding-left: 4px;
      line-height: 20px;
      letter-spacing: 0.28px;
      color: ${theme.colors.primary["900"]};
      font-size: ${theme.fontSize.medium};
      font-weight: ${theme.fontWeight.medium};
    }

    a:hover {
        text-decoration: none;
        color: ${theme.colors.primary[500]};
    }
`;

export const Link = styled(RouterLink)`
  &:hover {
    text-decoration: none;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.grey["100"]};
  border-radius: 8px;
  padding: 16px 8px;
  margin-bottom: 16px;

  ${({ Recently }) => Recently && css` 
    background-color: ${theme.colors.primary["0"]};
  `};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Main = styled(NormalColumn)`
  max-height: calc(100vh - 56px); 
  overflow-y: auto;
  scrollbar-width: 3px;
  padding-right: 15px;
`;
export const MainContent = styled.div`
  max-height: calc(100vh - 56px); 
  overflow-y: auto;
  scrollbar-width: 3px;
  padding-right: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  color: ${theme.colors.grey["850"]};
  font-size: ${theme.fontSize.xxLarge};
  font-weight: ${theme.fontWeight.bold};
  line-height: 32px;
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 320px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 6px 40px 6px 10px;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  
  &::placeholder {
    color: ${theme.colors.grey["300"]};
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
`;

export const Icon = styled.img`
  position: absolute;
  right: 12px;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const CountOfTemplates = styled.div`
  color: ${theme.colors.grey["700"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  padding: 7px 0 16px 0;
  
  span {
    padding-left: 4px;
    font-weight: ${theme.fontWeight.bold};
  }
`;

const slideInFromRight = keyframes`
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
`;

export const TemplatesContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 18px 8px 8px 8px;
  flex-wrap: wrap;
  overflow: hidden;
  scrollbar-width: 3px;
  max-height: ${({ scroll }) => scroll ? "100%": "auto" };
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${slideInFromRight} 500ms;
  width: ${({ isFilter }) => isFilter ? "250px" : "320px" };
  flex-basis: calc(25% - 15px);
  flex-grow: 0;
  flex-shrink: 0;
  height: 300px;
  box-shadow: 0px 4px 14px 0px #3A3B3D1A;
  transition: all 500ms;
  border: 1px solid ${theme.colors.grey["50"]};
  border-radius: 6px;
  background-color: ${theme.colors.white};
  cursor: pointer;

  :hover, :active {
    box-shadow: 0px 5px 12px 0px #3A3B3D1A, 0px 21px 21px 0px #3A3B3D14, 0px 131px 37px 0px #3A3B3D00;
    transform: scale(1.03);
  }

  :active {
    border-color: #B9B6FA;
  }

  ${({ Recently }) => Recently && css`
    height: 290px;

    @media(max-width: 1377px) {
      height: 320px;
    }
  `}
`;

export const IconContainer = styled.div`
  cursor: pointer;
  background: ${theme.colors.grey["0"]};
  min-height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
`;

export const TemplateMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px 16px;
`;

export const TemplateTitle = styled.span`
  cursor: pointer;
  color: ${({ Recently }) => Recently ? "#3734A3" : theme.colors.grey["700"]};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};;
  letter-spacing: 0.08px;
  line-height: 19.36px;

  display: -webkit-box;
  max-height: 48px;  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const TemplateDescription = styled.p`
  color: ${theme.colors.grey["600"]};
  font-size: ${theme.fontSize.medium};
  line-height: 20px; 
  padding-top: 6px;

  display: -webkit-box;
  max-height: 48px;  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ButtonsContainer = styled(NormalRow)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  width: auto;
  height: 100%;
  margin: 0 16px 16px 0;

  @media(max-width: 1377px) {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 0 0 16px 0;
    align-items: center;
  }
`;

export const NewTask = styled(Link)`
  ${({ disabled }) => disabled ?`
    box-shadow: rgba(134, 143, 160, 0.4) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
    color: rgb(70, 79, 96) !important;
    cursor: not-allowed;
  `:
    "box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);"};

  text-decoration: none;
  width: 120px;
  height: 22px;
  border-radius: 4px;
  background-color: white;
  color: ${theme.colors.grey["700"]};
  
  display: flex;
  padding: 0 8px;
  justify-content: center;
  align-items: center;

  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 18px;
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms;

  &:hover {
    color: ${theme.colors.grey["900"]};
    text-decoration: none;
    box-shadow: 0px 0px 0px 1px #464F6052, 0px 1px 1px 0px #0000001A;
  }

  &:active {
    color: ${theme.colors.grey["700"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  @media(max-width: 1377px){
    width: 90%;
  };
  @media(max-width: 880px){
    width: 85%;
  };
`;

export const SubmitAgain = styled(Link)`
  ${({ disabled }) => disabled && css`
      cursor: not-allowed;
      background: ${theme.colors.primary["500"]} !important;
      box-shadow: none !important;
  `};

  text-decoration: none;
  width: 120px;
  height: 22px;
  border: none;
  border-radius: 4px;
  background: ${theme.colors.primary["500"]};
  color: white;

  display: flex;
  padding: 0 8px;
  justify-content: center;
  align-items: center;

  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  line-height: 18px;
  box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms;

  &:hover {
    text-decoration: none;
    color: white;
    background: ${theme.colors.primary["600"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:active {
    background: ${theme.colors.primary["500"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  @media(max-width: 1377px){
    width: 90%;
  };
  @media(max-width: 880px){
    width: 85%;
  };
`;

export const FilterButton = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: ${theme.colors.primary["500"]};

  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

  &:hover {
    background: ${theme.colors.primary["600"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:active {
    background: ${theme.colors.primary["500"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:disabled {
    color: var(--indigo-0, #EDEDFC);
    background: var(--indigo-300, #9E9BF5);
    box-shadow: 0px 0px 0px 1px #9E9BF5, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const CollapseText = styled.span`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  white-space: nowrap;

  color: ${({ isActive }) => isActive 
    ? theme.colors.primary['600']
    : theme.colors.grey['900']};
`;

export const RecentlyTemplate = styled.div`
  margin: 6px 0;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RecentlyTemplateTime = styled.span`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 2%;
  color: ${theme.colors.grey["400"]};
`;

export const FilterContainer = styled(NormalColumn)`
  max-height: 100%;
  max-width: 250px;
  border-right: 1px solid ${theme.colors.grey['100']};
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ isOpen }) => isOpen 
    ? `
      width: 300px;
      padding: 30px 14px 20px 14px;
    `
    : `
      white-space: nowrap;
      padding-top: 30px;
      width: 0;
    `}
`;

export const FilterTitle = styled.div`
  color: ${theme.colors.grey['900']};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};
  letter-spacing: 0.08px;
  padding-bottom: 6px;
  white-space: nowrap;
`;

export const Tags = styled.div`
  color: var(--gray-400, #868FA0);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.36px;
  white-space: nowrap;
`;

export const SortBy = styled(NormalRow)`
  padding: 0 10px;
  justify-content: space-between;
  color: ${theme.colors.grey['400']};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  cursor: pointer;
  padding: 6px 0;
  white-space: nowrap;
  padding-right: 7px;

  &:hover {
    border-radius: 4px;
    background: ${theme.colors.primary['0']};
  }
`;

export const AmountofTags = styled.div`
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${theme.colors.primary['0']};

  color:  ${theme.colors.primary['500']};
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.medium};
  line-height: 16px;
  letter-spacing: 0.3px;
  white-space: nowrap;
`;

export const SortByContainer = styled(NormalColumn)`
  gap: 9px;
  margin-top: 12px;
  height: calc(100vh - 270px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 3px;
  white-space: nowrap;
`;

export const AlertContainer = styled.div`
  width: 100%;
  background: ${theme.colors.lightGrey};
  margin-top: 12px;
  padding: 16px 0 16px 70px;
  display: flex;
  gap: 115px;
`;

export const CommunicatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const TitleComunicat = styled.div`
  color: ${theme.colors.grey["900"]};
  font-size: ${theme.fontSize.mediumLarge};
  font-weight: ${theme.fontWeight.medium};
  line-height: 28px;
`;

export const BodyComunicat = styled.div`
  color: ${theme.colors.grey["600"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};
  line-height: 20px;
`;

export const StyledButton = styled.label`
  background-color: ${theme.colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  margin: 0;

  :disabled {
    box-shadow: 0px 0px 0px 1px #464F6033;
    background-color: ${theme.colors.grey["0"]};
    
    span {
      background-color: ${theme.colors.grey["400"]};
    }
  }

  ${({ isActive }) => isActive 
    ? `
      box-shadow: 0px 0px 0px 1px #5E5ADB, 0px 1px 1px 0px #0000001A;

      span {
        background-color: ${theme.colors.primary["500"]} !important;
      }
    `
    :`
      box-shadow: 0px 0px 0px 1px #464F6029, 0px 1px 1px 0px #0000001A;
      
      &:hover {
        box-shadow: 0px 0px 0px 1px #807CEA, 0px 1px 1px 0px #0000001A;
        span {
          background-color: ${theme.colors.primary["400"]} !important;
        }
      }
    `};
`;

export const Checkbox = styled.input`
  display: none !important;
`;

export const Line = styled.span`
  position: absolute;
  width: 70%;
  height: 1.5px;
  transition: all .3s;
  background-color: ${theme.colors.grey["400"]};
  transform-origin: center;
  transform: ${({ isActive, type }) => isActive 
    ? (type === 'line1' ? 'rotate(45deg)' : 'rotate(-45deg)') 
    : (type === 'line1' ? 'rotate(0deg)' : 'rotate(90deg)')};
`;

export const Img = styled.img`
  width: 16px;
  height: 16px;
`;

export const HorizontalLine = styled.span`
  display: flex;
  width: calc(100% - 16px);
  height: 1px;
  background-color: ${theme.colors.grey["50"]};
  margin: 12px 8px;
`;