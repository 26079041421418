/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable, { ReactTableDefaults } from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';
import moment from 'moment';
import { FaExchangeAlt } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import qs from 'qs';

import { queryToSettings, generateColumns, selectInputComponent  } from '../../utils/tasksgrid';
import CancelingActionModal from './cancelingActionModal';
import MovingActionModal from './movingActionModal';
import ColumnsModal from './columnsModal';
import { withSendRequest } from '../../../main/hoc/withSendRequest';
import RefreshFeature from '../../components/refreshFeature';
import CleanButton from '../../components/cleanButton';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import MiniAlert from '../../components/miniAlert';
import { getLS, setLS } from '../../../main/utils/localStorageProvider';
import DefaultFilter from './defaultFilter';
import Pagination from './pagination';
import { ReactComponent as EditIcon } from '../../../assets/img/edit.svg';
import BrowseDataButtons from './browseDataButtons';

/* ******* CUSTOMIZATION *********** */

const toggleableCustomColumnsIDs = [];
const customFacetsString = "";
const addCustomFieldsFunction = () => {};
const getCustomColumnsFunction = () => {};

/* ******* CUSTOMIZATION-END ******* */

const CheckboxTable = checkboxHOC(ReactTable);

@ErrorBoundary(props => props.t('job_grid'))
@withTranslation()
@withRouter
@connect(
  state => ({
    columnsStore: state.columns,
  })
)
@withSendRequest
export default class TasksGrid extends Component {
  constructor(props) {
    super(props);

    this.defaultFiltered = [];
    this.defaultSorted = "-submit_time";
    this.defaultPage = 1;
    this.defaultPageSize = 9;

    this.state = {
      data: [],
      pages: undefined,
      loading: true,
      selection: [],
      selectAll: false,
      cancelingModal: false,
      movingModal: false,
      columnsModal: false,
      pageSize: undefined,
      page: undefined,
      sorted: undefined,
      filtered: this.defaultFiltered,
      facets: undefined,
      tasksCount: undefined,
      toggleableColumns: Map(),
    };
  }

  componentDidMount() {
    this.initializeTasksGrid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search)
      this.initializeTasksGrid();
  }

  saveToQuery = (obj, keepFilters = true) => {
    const query = qs.parse(this.props.location.search.replace('?',''));
    const prevQuery = keepFilters ? query : {};
    const newQuery = {
      page: query.page ? query.page : this.defaultPage,
      page_size: query.page_size ? query.page_size : this.defaultPageSize,
      ordering: query.ordering ? query.ordering : this.defaultSorted,
      ...prevQuery,
      ...obj
    };
    this.props.history.push(this.props.location.pathname + '?' + qs.stringify(newQuery, { indices: false }));
  };

  getToggleableColumnsInitValues = () => {
    const toggleableColumnsIDs = [ "submit_time", "updated_time" ].concat(toggleableCustomColumnsIDs);
    let toggleableColumnsInitValues = Map({});
    toggleableColumnsIDs.forEach(element => {
      toggleableColumnsInitValues = toggleableColumnsInitValues.set(element, (getLS(element) !== "false"));
    });
    return toggleableColumnsInitValues;
  };

  initializeTasksGrid = () => {
    this.saveToQuery();
    const query = qs.parse(this.props.location.search.replace('?',''));
    if (query.page_size && query.page)
      this.fetchData();
    const settings = queryToSettings(this.props.location.search);
    this.setState({
      filtered: settings && settings.filtered,
      sorted: settings && settings.sorted && settings.sorted,
      page: settings && settings.page,
      pageSize: settings && settings.pageSize,
      toggleableColumns: this.getToggleableColumnsInitValues(),
    });
  };

  // Copied from https://react-table.js.org/#/story/select-table-hoc
  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [ ...this.state.selection ];
    key = key.replace('select-','');
    const keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    }
    else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  // Copied from https://react-table.js.org/#/story/select-table-hoc
  // Current implementation: selecting all tasks visible on current (filtered, ordered) page
  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = key => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection.includes(key);
  };

  fetchData = () => {
    this.setState({ loading: true , selection: [], selectAll: false });
    const searchProps = this.props.location.search.replace('?','').replace('grant=all', '');
    return this.props.sendRequest("get",
      '/jobs/search/?' + searchProps + '&facet=queue&facet=major_state&facet=template_name&facet=grant' + customFacetsString)
      .then((response) => {
        let rows = response.data.results.map(row => {

          // Set _id field required by react-table
          row._id = row.id;

          addCustomFieldsFunction(row);

          return row;
        });
        
        this.setState({
          data: rows,
          pages: Math.ceil(response.data.count / this.state.pageSize),
          loading: false,
          facets: response.data.facets,
          tasksCount: response.data.count,
        });
      });
  };

  filtersToQuery = (filtered) => {
    let query = {};
    filtered.forEach(filter => {
      if (typeof filter.value === 'object' && filter.value !== null && !(Array.isArray(filter.value))) {
        // Filter type: date range
        Object.keys(filter.value).forEach(item => {
          if (moment.isMoment(filter.value[item]))
            query[filter.id + "_" + item] = filter.value[item].toISOString();
          else
            return false;
        });
      }
      else {
        if (filter.id === 'grant' && Array.isArray(filter.value)) {
          query[filter.id] = filter.value.filter(item => item !== 'all');
        } 
        else {
          // Filter type: string (attribute or simple field)
          query[filter.id] = filter.value;
        }
      }
    });
    query.page = 1;
    this.saveToQuery(query, false);
  };

  debouncedFiltersToQuery = _.debounce(this.filtersToQuery, 400, false);

  onFilteredChange = (filtered) => {
    this.setState({ filtered }, () => this.debouncedFiltersToQuery(filtered));
  };

  onSortedChange = (sorted) => {
    this.saveToQuery({
      ordering: (sorted[0].desc ? '-' : '') + sorted[0].id,
      page: 1
    });
  };

  onPageChange = (page) => {
    this.saveToQuery({ page: page + 1 });
  };

  onPageSizeChange = (pageSize) => {
    this.saveToQuery({
      page_size: pageSize,
      page: 1
    });
  };

  toggleStateField = field => event => {
    this.setState({ toggleableColumns: this.state.toggleableColumns.update(field, value => !value) },
      () => setLS(field, this.state.toggleableColumns.get(field))
    );
    event.stopPropagation();
  };

  openCancelingModal = (ids) => {
    this.setState({ cancelingModal: ids });
  };

  openMovingModal = (ids) => {
    this.setState({ movingModal: ids });
  };

  renderCancelingActionModal() {
    return this.state.cancelingModal && (
      <CancelingActionModal
        idsArray={this.state.cancelingModal}
        onClose={() => this.setState({ cancelingModal: false }) }
      />
    );
  }

  renderMovingActionModal() {
    return this.state.movingModal && (
      <MovingActionModal
        idsArray={this.state.movingModal}
        onClose={() => this.setState({ movingModal: false }) }
      />
    );
  }

  renderColumnsModal(columns) {
    return this.state.columnsModal && (
      <ColumnsModal
        onClose={() => this.setState({ columnsModal: false }) }
      />
    );
  }

  render() {
    const { t } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { data, loading, selectAll, tasksCount } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      SelectInputComponent: selectInputComponent,
      SelectAllInputComponent: selectInputComponent,
      getTrProps: (s, rowInfo) => {
        return {
          className: rowInfo && this.isSelected(rowInfo.original._id) ? "selected" : "non-selected"
        };
      }
    };

    const columns = generateColumns(
      this.props.t,
      this.state.toggleableColumns,
      this.toggleStateField,
      this.props.columnsStore,
      this.openCancelingModal,
      this.state.facets,
      getCustomColumnsFunction(this.props.t, this.state.facets, this.state.toggleableColumns, this.toggleStateField),
    );

    // Prepare list of hidden columns which are used to filtering
    let hiddenFilteredColumns = [];
    if (this.state && this.state.filtered && this.state.filtered.length > 0) {
      const filteredColumns = this.state.filtered.map(item => item.id);
      const visibleColumns = columns.map(item => item.accessor);
      hiddenFilteredColumns = _.difference(filteredColumns, visibleColumns).filter(column => column !== 'grant_default');
    }

    // Check if sorting by hidden column
    let hiddenSortedColumn = null;
    if (this.state && this.state.sorted && this.state.sorted.length > 0) {
      const sortedColumn = this.state.sorted[0].id;
      const visibleColumns = columns.map(item => item.accessor);
      hiddenSortedColumn = visibleColumns.indexOf(sortedColumn) === -1 ? sortedColumn : null;
    }
    return (
      <div id="table-area-container">
        <div id="table-area">
          {this.renderCancelingActionModal()}
          {this.renderMovingActionModal()}
          {this.renderColumnsModal(columns)}
          <div id="tasks-header">
            <div id="filters-header" className='p-4'>
              {t('filters')}
              {this.state && this.state.filtered && this.state.filtered.length>0 && <CleanButton callback={() => this.onFilteredChange([])}/>}
              {hiddenFilteredColumns.length > 0 && (
                <MiniAlert
                  content={t('filter_by_hidden_columns')}
                  tooltip={t('hidden_fields_used_to_filtering') + ': ' + hiddenFilteredColumns.join(', ')}
                />
              )}
            </div>
            <div className="above-tasksgrid">
              <Row>
                <Col className="left-panel pt-4 pl-4 ml-1">
                  <h1>{t('jobs')}</h1>
                </Col>
                <Col className="right-panel">
                  <Button
                    data-testid="modify-columns"
                    id="modify-columns"
                    color="link"
                    size="sm"
                    className='pb-2 pt-4 pr-5'
                    onClick={()=> {this.setState({ columnsModal: true });}}
                  >
                    <EditIcon className='p-1'/>
                    <span className='p-1'>{t('modify_columns')}</span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col id="all-task-count" className="left-panel pl-4 ml-1 center-vertical ">
                  <span className='mr-1'>{t('filtered_job_count')}</span><b>{tasksCount}</b>
                </Col>
                <div className="d-flex flex-column align-items-center">
                  {hiddenSortedColumn && (
                    <MiniAlert
                      content={t('sort_by_hidden_columns')}
                      tooltip={t('hidden_field_used_to_sorting') + ': ' + hiddenSortedColumn}
                    />
                  )}
                  {
                    !!this.state.selection.length &&
                    <div className="mt-2">
                      <Button
                        className="mx-4"
                        id="cancel-selected-button"
                        data-testid="cancel-selected-button"
                        outline
                        color="danger"
                        size="sm"
                        onClick={()=> {this.openCancelingModal(this.state.selection);}}
                      >
                        {t('cancel_selected')}
                        <MdCancel className="ml-2"/>
                      </Button>
                      <Button
                        id="move-selected-button"
                        data-testid="move-selected-button"
                        outline
                        color="secondary"
                        size="sm"
                        onClick={()=> {this.openMovingModal(this.state.selection);}}
                      >
                        {t('move_selected')}
                        <FaExchangeAlt className="ml-2"/>
                      </Button>
                    </div>
                  }
                </div>
                <Col className="right-panel pr-5">
                  <RefreshFeature
                    minValue={10}
                    maxValue={100}
                    default={30}
                    fetchData={this.fetchData}
                  />
                </Col>
              </Row>
            </div>
          </div>

          {Number.isInteger(this.state.pages) && this.state.pageSize &&
          <CheckboxTable
            PaginationComponent={Pagination}
            ref={r => (this.checkboxTable = r)}
            columns={columns}
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={data}
            pages={this.state.pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            column={{ ...ReactTableDefaults.column, Filter: DefaultFilter }}
            filterable
            page={this.state.page}
            onPageChange={this.onPageChange}
            filtered={this.state.filtered}
            onFilteredChange={this.onFilteredChange}
            sorted={this.state.sorted}
            onSortedChange={this.onSortedChange}
            pageSize={this.state.pageSize}
            onPageSizeChange={this.onPageSizeChange}
            pageSizeOptions={[ 5, 10, 20, 50, 100, 200, 500 ]}
            defaultPageSize={9}
            minRows={0}
            showPaginationBottom
            className="-highlight -thin mt-2"
            getTheadFilterProps={() => ({ className: "filters-panel" })}
            {...checkboxProps}
          />
          }
          <BrowseDataButtons />
        </div>
      </div> 
    );
  }
}

TasksGrid.propTypes = {
  t: PropTypes.func, //HOC
  columnsStore: PropTypes.object, //HOC
  history: PropTypes.object, //HOC
  location: PropTypes.object,
  sendRequest: PropTypes.func, //HOC
};
