/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExternalFormIframe, IframeButton, IframeContainer } from '../../styles/styled-components/externalForm';
import { FullScreen, MinScreen } from '../../../assets/img/task/icons';

const ExternalForm = ({ GUIUrl, onSubmit, templateData }) => {
  const [ isFullScreen, setIsFullScreen ] = useState(false);

  useEffect(() => {
    const handleMessage = (e) => {
      if (GUIUrl && e.origin === new URL(GUIUrl).origin) {
        if (e.data.id === "getTemplate") {
          //getTemplate message
          e.ports[0].postMessage({
            data: templateData.toJS(),
            id: "getTemplate",
          });
        }
        else {
          //Submit message
          onSubmit({ formData: e.data });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [ GUIUrl, templateData ]);

  return (
    <IframeContainer isFullScreen={isFullScreen}>
      <IframeButton onClick={() => setIsFullScreen(!isFullScreen)} id="toggleButton">
        {isFullScreen ? <MinScreen alt="Minimize Screen" /> : <FullScreen alt="Full Screen" />}
      </IframeButton>
      <ExternalFormIframe
        src={GUIUrl}
        title="External GUI"
        id="external_gui"
      />
    </IframeContainer>
  );
};
 
ExternalForm.propTypes = {
  GUIUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  templateData: PropTypes.object.isRequired,
};

export default ExternalForm;
 