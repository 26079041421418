import styled, { css } from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SpaceSpinner = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 170px;
  min-width: 876px;
`;

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  max-width: 500px;
  min-width: 340px;
  height: 100px;
  margin-top: 100px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 13px -8px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 13px -8px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 13px -8px rgba(66, 68, 90, 1);
  animation-name: change-size;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  @keyframes change-size {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
  }
`;

export const Text = styled.span`
  text-align: center;
  font-weight: ${theme.fontWeight.medium};
`;

export const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 50vh;
  width: 100%;
  height: 300px;
  margin-top: 16px;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${({ isFullScreen }) => isFullScreen && `
    position: fixed;
    width: 100%;
    height: calc(100vh) !important;
    top: 0;
    left: 0;
    z-index: 1024;

    iframe {
      height: 100vh !important;
    }
  `}
`;

export const IframeButton = styled.button`
  z-index: 1000;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px #464F6029, 0px 1px 1px 0px #0000001A;

  ${({ fullScreen }) => fullScreen && css`
    position: absolute;
    top: 10px;
    right: 10px;
  `};

  &:hover {
    box-shadow: 0px 0px 0px 1px #807CEA, 0px 1px 1px 0px #0000001A;
    
    svg {
      path {
        fill: ${theme.colors.primary["500"]};
      };
    }
  }

  img {
    width: 16px !important;
    height: 16px !important;
  }
`;