import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { 
  Breadcrumbs, 
  Container, 
  CountOfTemplates,
  FilterButton,  
  Header, 
  HeaderTitle, 
  Icon,
  Link,
  MainContent,
  SearchContainer, 
  SearchInput
} from '../../styles/styled-components/newTemplates';
import { NormalColumn, NormalRow } from '../../styles/styled-components/GlobalStyles';
import { Angel, FilterIcon, SearchIcon } from '../../../assets/img/templates/icons.js';
import { loadTemplates } from '../../actions/templates';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import AllTemplates from './allTemplates';
import Loading from '../../../main/components/loading';
import RecentlyTemplates from './recentlyTemplates';
import TemplateDetails from './templateDetails';
import TemplateFilter from './templateFilter';
import { __env } from '../../../envloader';

const NewTemplates = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectDates = state => ({
    templates: state.templates.get('data'),
    isLoading: state.templates.get('isLoading')
  });

  const { templates, isLoading } = useSelector(selectDates);

  const [ activeUrl, setActiveUrl ] = useState(null);
  const [ grantsStatus, setGrantsStatus ] = useState();
  const [ isFilter, setIsFilter ] = useState(false);
  const [ recetlyTemplates, setRecentlyTemplates ] = useState(null);
  const [ search, setSearch ] = useState("");

  const getRecentlyTemplate = () => {
    axiosInstance({
      url: '/templates/recently-used/',
      method: "get",
      params: {
        count: 4
      }
    }).then(res => setRecentlyTemplates(res.data?.templates));
  };

  const getGrants = () => {
    if (__env.BLOCK_SUBMIT_FOR_NO_GRANTS) {
      axiosInstance.get('user/')
        .then(res => setGrantsStatus(res.data['user-info']?.grants?.length || 0));
    };
  };

  useEffect(() =>{
    getGrants();
    getRecentlyTemplate();
    dispatch(loadTemplates());
  }, [ dispatch ]);

  useEffect(() => {
    if (!location.search.includes("details")) {
      setActiveUrl(null);
    }
  }, [ location.search ]);

  if (isLoading){
    return <Loading />;
  }

  if (activeUrl) {
    return <TemplateDetails templateUrl={activeUrl} />;
  }

  return (
    <Container>
      <NormalRow fullHeight style={{ alignItems: "flex-start" }}>
        <TemplateFilter isOpen={isFilter}/>
        <NormalColumn>
          <MainContent>
            <Breadcrumbs style={{ margin: "12px 0" }}>
              <Link to={'/ui/tasks/'}>{t('new_templates.main_page')}</Link>
              <img src={Angel} alt="Angel-right"/>
              <div>{t('templates')}</div>
            </Breadcrumbs>
            <Header>
              <HeaderTitle>{t('new_templates.all_templates')}</HeaderTitle>
              <NormalRow style={{ width: "auto" }}>
                <SearchContainer>
                  <SearchInput
                    onChange={(e) => setSearch((e.target.value.toLowerCase()))}
                    type="text"
                    placeholder={t('new_templates.search_for_template')}
                  />
                  <Icon src={SearchIcon} alt="search" />
                </SearchContainer>
                {/* <FilterButton disabled onClick={() => setIsFilter(!isFilter)}>
                  <img src={FilterIcon} alt="filterIcon"/>
                </FilterButton> */}
              </NormalRow>
            </Header>
            <CountOfTemplates>{t('new_templates.total_templates')}<span>{templates?.size || 0}</span></CountOfTemplates>
            <RecentlyTemplates grantsStatus={grantsStatus} setActiveUrl={setActiveUrl} recetlyTemplates={recetlyTemplates}/>
            <AllTemplates grantsStatus={grantsStatus} search={search} templates={templates} setActiveUrl={setActiveUrl} isFilter={isFilter}/>
          </MainContent>
        </NormalColumn>
      </NormalRow>
    </Container>
  );
};

export default NewTemplates;