import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory  } from 'react-router-dom';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Trans, useTranslation } from 'react-i18next';

import {
  IconContainer,
  NewTask,
  TemplateContainer, 
  TemplatesContainer, 
  TemplateMain, 
  TemplateTitle, 
  TemplateDescription,
  AlertContainer,
  CommunicatContainer,
  TitleComunicat,
  BodyComunicat,
  ButtonsContainer,
  CardContainer,
} from '../../styles/styled-components/newTemplates';
import TemplateIcon from '../../../assets/img/templates/template-icon';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import { AllTemplatesIcon, NoTemplates } from '../../../assets/img/templates/icons.js';
import { getDefaultGrant } from '../../utils/defaultGrant';
import CustomCardButton from '../customCardButton.jsx';

const AllTemplates = ({ grantsStatus, isFilter, search, setActiveUrl, templates }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const shortLngCode = getCurrentLanguageShortCode();
  const grantDefaultQuery = getDefaultGrant(location);

  const [ isActive, setIsActive ] = useState(true);

  const compareTemplates = (a, b) => {
    const nameA = a[1]?.get('name')?.toUpperCase() || '';
    const nameB = b[1]?.get('name')?.toUpperCase() || '';
    
    return nameA.localeCompare(nameB);
  };

  const allArrays = templates?.size > 0 && templates
    .entrySeq()
    .sort(compareTemplates)
    ._array.filter((array) =>
      array[1].get("name").toLowerCase().includes(search)
    )
    .map(([ key, item ]) => {
      return Object.values([ key, item ]);
    });

  const showTemplateDetails = (url) => {
    setActiveUrl(url);
    history.push(`/ui/templates?details${grantDefaultQuery && `&grant_default=${grantDefaultQuery}`}`);
  };

  return (
    <CardContainer isActive={isActive}>
      <CustomCardButton
        icon={AllTemplatesIcon}
        id="checkAllTemplates"
        alt="AllTemplatesIcon"
        isActive={isActive}
        setIsActive={setIsActive}
        title={t('new_templates.all_templates')}
      />
      {allArrays ?
        <Collapse isOpen={isActive}>
          <TransitionGroup>
            <TemplatesContainer scroll>
              {allArrays.map((template, index) => (
                <CSSTransition
                  key={template[0]}
                >
                  <TemplateContainer isFilter={isFilter} onClick={() => showTemplateDetails(template[0])}>
                    <IconContainer>
                      {template[1].get('icon_url') 
                        ? <img src={template[1].get('icon_url')} alt="template icon" className="mw-50 mh-75"/> 
                        : <TemplateIcon alt="" className="mw-50 mh-75"/>
                      }  
                    </IconContainer>
                    <TemplateMain>
                      <TemplateTitle>{template[1].get('name')}</TemplateTitle>
                      <TemplateDescription>{template[1].getIn([ 'description', shortLngCode ])}</TemplateDescription>
                    </TemplateMain>
                    <ButtonsContainer>
                      <NewTask
                        id={`template-button-${index}`}
                        disabled={!grantsStatus}
                        onClick={(e) => { e.stopPropagation(); if (!grantsStatus) e.preventDefault();}}
                        to={`/ui/submit?templateUrl=${template[0]}${grantDefaultQuery 
                          ? `&grant_default=${grantDefaultQuery}` 
                          : ''}`}  
                      >
                        {t('new_templates.assign_task')}
                      </NewTask>
                      {!grantsStatus &&
                        <UncontrolledTooltip placement='bottom' target={`template-button-${index}`}>
                          {t('template_view.no_grant_info')}
                        </UncontrolledTooltip>
                      }
                    </ButtonsContainer>
                  </TemplateContainer>
                </CSSTransition>
              ))}
            </TemplatesContainer>
          </TransitionGroup>
        </Collapse>
        :
        <Collapse isOpen={isActive}>
          <AlertContainer>
            <img src={NoTemplates} alt="No Templates"/>
            <CommunicatContainer>
              <TitleComunicat>{t('new_templates.no_templates')}</TitleComunicat>
              <BodyComunicat>
                <Trans i18nKey="new_templates.no_templates_body">
                  Nie masz jeszcze szablonu. 
                  <br/>Przepraszamy za niedogodność, pracujemy nad nowym
                </Trans>
              </BodyComunicat>
            </CommunicatContainer>
          </AlertContainer>
        </Collapse>
      }
    </CardContainer>
  );
};

export default AllTemplates;

AllTemplates.propTypes = {
  grants: PropTypes.number,
  grantsStatus: PropTypes.number,
  isFilter: PropTypes.bool,
  search: PropTypes.string, 
  setActiveUrl: PropTypes.func,
  templates: PropTypes.object,
};