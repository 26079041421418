import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import axios from '../../../main/utils/axios/axiosInstance';
import { Container } from '../../styles/styled-components/jobs/Results';
import { Alert, Text } from './../../styles/styled-components/jobs/Monitoring';
import IframeDisplay from './IframeDisplay';
import Loading from '../../../main/components/loading';

const Results = ({ url, files, storages }) => {
  const { t } = useTranslation();

  const [ fullScreenIndex, setFullScreenIndex ] = useState(null);
  const [ activePanels, setActivePanels ] = useState(null);
  const [ storageUrlsData, setStorageUrlsData ] = useState(null);

  const activePanelsRef = useRef(activePanels);

  const createFinalUrl = (outputFile) => {
    const storageUrl = outputFile ? outputFile.ui_url : '';
    const [ urlWithoutQuery, query ] = storageUrl.includes('?') ? storageUrl.split('?') : [ storageUrl, '' ];
    const newQuery = new URLSearchParams(query);
    newQuery.set('lng', i18n.language);
    return `${urlWithoutQuery}?${newQuery.toString()}`;
  };

  useEffect(() => {
    const initialPanelStates = files
      ?.filter(file => file.type === 'OUTPUT' && file.ui_url)
      .map((el) => ({
        name: el.name,
        ui_url: el.ui_url,
        finalURL: createFinalUrl(el),
      }));

    setStorageUrlsData(initialPanelStates || []);
    
    const handleMessage = (e) => {
      if (url && e.origin === new URL(url).origin && e.data.id === "getFile") {
        if (initialPanelStates.length > 0) {
          const basicURL = initialPanelStates[0].ui_url.split('?')[0];
          axios.get(`${basicURL}${e.data?.data}?format=raw`)
            .then(res => {
              e.ports[0].postMessage({
                data: JSON.parse(JSON.stringify(res)),
                error: null,
                id: "getFile",
              });
            })
            .catch(err => {
              e.ports[0].postMessage({
                data: null,
                error: err,
                id: "getFile",
              });
            });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [ url, files ]);

  useEffect(() => {
    if (storageUrlsData !== null) {
      const isUrl = Boolean(url);
      const newActivePanels = Array(storageUrlsData?.length).fill(false).concat(isUrl);

      if (!isUrl) {
        newActivePanels[0] = true;
      }

      if (activePanelsRef.current?.length > 0 && JSON.stringify(activePanelsRef.current) !== JSON.stringify(newActivePanels)) {
        setActivePanels(activePanelsRef.current);
      }
      else {
        setActivePanels(newActivePanels);
      }
    }
  }, [ storageUrlsData, url ]);
  
  useEffect(() => {
    activePanelsRef.current = activePanels;
  }, [ activePanels ]);

  if (storageUrlsData === null || activePanels === null) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  };

  return (
    <Container>
      { (url || storageUrlsData.length > 0) ?
        <>
          { url && storages && 
            <IframeDisplay
              panelUrl={url}
              index={activePanels.length - 1}
              isActive={activePanels[activePanels.length - 1]}
              isFullScreen={fullScreenIndex === activePanels.length - 1}
              setActivePanels={setActivePanels}
              setFullScreenIndex={setFullScreenIndex}
              title={t(`task.task_results`)}
            />
          }
          {storageUrlsData?.map((storage, index) => (
            <IframeDisplay
              key={index}
              panelUrl={storage.finalURL}
              index={index}
              isActive={activePanels[index]}
              isFullScreen={fullScreenIndex === index}
              setActivePanels={setActivePanels}
              setFullScreenIndex={setFullScreenIndex}
              title={`${(new URL (storage?.ui_url))?.pathname?.endsWith("/") ? "Folder" : t(`task.file`)} ${storage?.name}`}
            />
          ))}
        </>
        : 
        <Alert>
          <Text>{t(`task.alert`)}</Text>
        </Alert>
      }
    </Container>
  );
};

Results.propTypes = {
  url: PropTypes.string,
  files: PropTypes.array,
  storages: PropTypes.object
};

export default Results;