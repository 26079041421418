import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import Loading from '../../../main/components/loading';
import axiosInstance from '../../../main/utils/axios/axiosInstance';

const BrowseDataButtons = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ providers, setProviders ] = useState();

  const getProviders = () => {
    setIsLoading(true);
    axiosInstance.get("/config/fileproviders")
      .then((response) => {
        setProviders(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => getProviders(), []);

  if (isLoading) {
    return <Loading />;
  };

  return (
    <div className="center">
      {
        providers?.filter(ele => ele.type === 'url').map(ele => (
          <a key={ele.label} href={ele.url} target='__blank' className="mb-2 mx-2">
            <Button>
              {ele.label}
            </Button>
          </a>
        ))
      }
    </div>
  );
};

export default BrowseDataButtons;