import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import {
  Container,
  SpaceSpinner,
  Alert,
  Text
} from '../../styles/styled-components/jobs/Monitoring';
import IframeDisplay from './IframeDisplay';

const Monitoring = ({ graphes, isLoadingGraphes, url, jobId, title }) => {
  const { t, i18n } = useTranslation();

  const [ fullScreenIndex, setFullScreenIndex ] = useState(null);
  const [ activePanels, setActivePanels ] = useState(Array(graphes?.panels?.length).fill(true).concat(url ? true : false));

  useEffect(() => {
    const newQuery = new URLSearchParams(url?.split('?')[1] || '');
    newQuery.set('lng', i18n.language);
  }, [ url, i18n.language ]);

  return (
    <Container>
      {isLoadingGraphes 
        ? <SpaceSpinner><Spinner color="primary" /></SpaceSpinner> 
        : graphes?.panels?.map((panelUrl, index) =>
          <IframeDisplay
            key={index}
            panelUrl={panelUrl}
            index={index}
            isActive={activePanels[index]}
            isFullScreen={fullScreenIndex === index}
            setActivePanels={setActivePanels}
            setFullScreenIndex={setFullScreenIndex}
            title={false}
          />
        )
      }
      {url &&
        <IframeDisplay
          panelUrl={url}
          index={activePanels.length - 1}
          isActive={activePanels[activePanels.length - 1]}
          isFullScreen={fullScreenIndex === activePanels.length - 1}
          setActivePanels={setActivePanels}
          setFullScreenIndex={setFullScreenIndex}
          title={false}
        />
      }
      {!url &&
        <Alert><Text>{t('task.alert')}</Text></Alert>
      }
    </Container>
  );
}; 

export default Monitoring;

Monitoring.propTypes = {
  graphes: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  isLoadingGraphes: PropTypes.bool,
  i18n: PropTypes.object, //HOC
  jobId: PropTypes.number,
  url: PropTypes.string,
  t: PropTypes.func, //HOC
  title: PropTypes.string
};