/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, Collapse, NavbarBrand } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import qs from 'qs';

import Login from '../../main/containers/login';
import { __env } from '../../envloader';
import LogoImg from './../../assets/img/qcg-logo.svg';
import SelectDefaultQuery from '../components/selectDefaultQuery';
import { CustomDropdown, CustomDropdownItem, CustomDropdownMenu, CustomDropdownToggle, StyledNavbar } from '../styles/styled-components/topbar';
import { Helper, Panel, Privacy, Raport } from '../../assets/img/topBar/icons';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData')
  })
)
class TopBar extends Component {

  state = {
    isOpen: false
  };

  toggleIsOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  renderNavItem(activePaths, address, name) {
    return (
      <NavItem>
        <NavLink className='center' tag={Link} to={address} active={activePaths.includes(this.props.location?.pathname)}>
          {name}
        </NavLink>
      </NavItem>
    );
  }

  processUrl = (url, replaceArray = []) => 
    replaceArray.reduce((accUrl, item) => accUrl.replace(`{${item.tag}}`, item.value), url);

  renderReportButton() {
    const { loginStore, location } = this.props;
    const SUPPORT_URL_PATTERN_TEMPLATE = __env.SUPPORT_URL_PATTERN_TEMPLATE;
    const SUPPORT_URL_PATTERN_GENERAL = __env.SUPPORT_URL_PATTERN_GENERAL;
  
    if (!loginStore) return null;
  
    if (location.pathname === '/ui/submit' && SUPPORT_URL_PATTERN_TEMPLATE) {
      const parsedSearch = qs.parse(location.search, { ignoreQueryPrefix: true });
      const href = this.processUrl(SUPPORT_URL_PATTERN_TEMPLATE, [ { tag: 'TEMPLATE_ID', value: parsedSearch.templateUrl } ]);
      return (
        <CustomDropdownItem href={href}>
          <img src={Raport} alt="Raport icon"/>
          {this.props.t('topbar.report_problem')}
        </CustomDropdownItem>
      );
    }
  
    if (SUPPORT_URL_PATTERN_GENERAL) {
      return (
        <CustomDropdownItem href={SUPPORT_URL_PATTERN_GENERAL}>
          <img src={Raport} alt="Raport icon"/>
          {this.props.t('topbar.report_problem')}
        </CustomDropdownItem>
      );
    }
  
    return null;
  };

  render() {
    const { t } = this.props;
    const parsedSearch = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const grantDefaultQuery = parsedSearch.grant_default;
    let additionalQuery = '';

    if (grantDefaultQuery !== undefined) {
      additionalQuery = `?grant_default=${grantDefaultQuery}`;
    } 

    return (
      <StyledNavbar expand dark>

        <NavbarBrand tag={Link} to={`/${additionalQuery}`}>
          <span>
            <img src={LogoImg} className="logo" alt="logo"/>
          </span>
          <span id="main-title">{__env.PORTAL_DISPLAY_NAME}</span>
        </NavbarBrand>

        <Collapse navbar>
          {
          //show navitems only when logged in
            this.props.loginStore &&
            <>
              <Nav navbar pills>
                {__env.ENABLE_TEMPLATES_AND_SUBMIT
                  && this.renderNavItem(
                    [ "/ui/templates", "/ui/submit" ],
                    `/ui/templates${additionalQuery}`,
                    t('submit')
                  )
                }
                {this.renderNavItem([ "/ui/tasks" ], `/ui/tasks${additionalQuery}`, t('jobs'))}
                <SelectDefaultQuery />
              </Nav>
            </>
          }

          <Nav className="ml-auto center" navbar pills >
            <CustomDropdown isOpen={this.state.isOpen} toggle={this.toggleIsOpen}>
              <CustomDropdownToggle>
                <img src={Helper} alt="helper icon"/>
              </CustomDropdownToggle>
              <CustomDropdownMenu>
                <CustomDropdownItem>
                  <img src={Privacy} alt="Privacy icon"/>
                  {this.props.t('topbar.privacy')}
                </CustomDropdownItem>
                <CustomDropdownItem>
                  <img src={Panel} alt="Panel icon"/>
                  {this.props.t('topbar.support_panel')}
                </CustomDropdownItem>
                {this.renderReportButton()}
              </CustomDropdownMenu>
            </CustomDropdown>
            <div className='center login'>
              <Login location={this.props.location}/>
            </div>
          </Nav>
        </Collapse>

      </StyledNavbar>
    );
  }
}

TopBar.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
  location: PropTypes.object,
  grant: PropTypes.string,
  setGrant: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(TopBar);
