import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Collapse, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Button from '../tasks/button';
import { Download } from '../../../assets/img/task/icons';
import {
  Details,
  DetailsUp,
  DetailsUpRow,
  DetailsUpRowTitle,
  DetailsUpRowText,
  DetailsDown,
  DetailsDownRow,
  DetailsDownRowTitle,
  History,
  HistoryRow,
  HistoryText
} from '../../styles/styled-components/jobs/OverView';
import TaskState from '../../components/taskState';
import { formatDate } from '../../utils/tasksgrid';
import * as notify from '../../utils/notify';
import CustomCardButton from '../customCardButton';
import { CardContainer, HorizontalLine } from '../../styles/styled-components/newTemplates';

const Overview = ({ data, history, calculations }) => {
  const { t } = useTranslation();

  const [ activeAdvanced, setActiveAdvanced ] = useState(true);
  const [ activeHistory, setActiveHistory ] = useState(true);

  const renderUpTask = (title, value, id) => (
    <DetailsUpRow id={id}>
      <DetailsUpRowTitle>{t(title)}:</DetailsUpRowTitle>
      <DetailsUpRowText>{value}</DetailsUpRowText>
    </DetailsUpRow>
  );

  const renderDownTask = (title, value) => (
    <DetailsDownRow>
      <DetailsDownRowTitle>{t(title)}:</DetailsDownRowTitle>
      {data?.get(value)}
    </DetailsDownRow>
  );

  const formatTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
  };

  const handleCopy = (password) => {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
      navigator.clipboard.writeText(password)
        .then(() => {
          notify.success("", t('task.copy_success'));
        })
        .catch(() => {
          notify.error("", t('task.copy_error'));
        });
    } 
    else {
      notify.error("", t('task.copy_error'));
    }
  };

  const startTime = data.get('start_time');
  const finishTime = data.get('finish_time');
  const completingTime = history?.find(r => r.get('state') === "COMPLETING")?.get('created');

  const calculationTime = () => {
    if (data) {
      const now = moment();
      const start_time = moment(startTime);
      const finish_time = moment(finishTime);
      const duration = finish_time.isValid()
        ? moment.duration(finish_time.diff(start_time))
        : completingTime && history
          ? moment.duration(completingTime.diff(start_time))
          : moment.duration(now.diff(start_time));

      const { days, hours, minutes, seconds } = {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };

      const timeString = [
        days && `${days}d`,
        hours && `${hours}h`,
        minutes && `${minutes}min`,
        seconds && `${seconds}s`,
      ].filter(Boolean).join(' ');

      return timeString;
    }
  };

  return (
    <Details>
      <DetailsUp>
        {renderUpTask('task.template', data.get('template_name'))}
        {renderUpTask('task.order_time', moment(data.get('submit_time')).fromNow(), "CalculationTimeTooltip")}
        <UncontrolledTooltip style={{ whiteSpace: "nowrap", maxWidth: "none", width: "auto" }} placement="bottom-start" target="CalculationTimeTooltip">
          {formatTimestamp(data.get('submit_time'))}
        </UncontrolledTooltip>
        {startTime && renderUpTask('task.computational_time', calculationTime())}
        {calculations && Object.keys(calculations).length > 0 &&
          <DetailsUpRow>
            <DetailsUpRowTitle>{t('task.calculations')}:</DetailsUpRowTitle>
            {Object.entries(calculations).map(([ key, value ]) =>
              <ButtonGroup key={key}>
                <Button
                  text={key}
                  icon={Download}
                  onClick={() => window.open(value?.url, "_blank")}
                  style={{ borderRadius: "4px 0 0 4px" }}
                />
                <Button
                  onClick={() => handleCopy(value?.secret)}
                  text={t('task.copy_password')}
                  style={{ borderRadius: "0 4px 4px 0" }}
                />
              </ButtonGroup>
            )}
          </DetailsUpRow>
        }
      </DetailsUp>
      <CardContainer isActive={activeAdvanced}>
        <CustomCardButton
          id="advanced"
          isActive={activeAdvanced}
          setIsActive={setActiveAdvanced}
          title={t('task.advanced')}
        />
        <Collapse isOpen={activeAdvanced}>
          <HorizontalLine />
          <DetailsDown>
            {renderDownTask('task.task_ID', 'id')}
            {renderDownTask('task.requesting_User_D', 'user')}
            {renderDownTask('task.template', 'template_name')}
            {renderDownTask('task.template_Version', 'template_version')}
            {renderDownTask('task.grant', 'grant')}
            {renderDownTask('task.current_Queue', 'queue')}
          </DetailsDown>
        </Collapse>
      </CardContainer>
      {history &&
        <CardContainer isActive={activeHistory}>
          <CustomCardButton
            id="history"
            isActive={activeHistory}
            setIsActive={setActiveHistory}
            title={t('task.history')}
          />
          <Collapse isOpen={activeHistory}>
            <HorizontalLine />
            <History>
              {history.map(record =>
                <HistoryRow key={record.get('created')}>
                  <HistoryText>{formatDate(record.get('created'), false, record.get('event_id') + '_created')}</HistoryText>
                  <td><TaskState state={record.get('state')} isDetailState /></td>
                </HistoryRow>
              )}
            </History>
          </Collapse>
        </CardContainer>
      }
    </Details>
  );
};

Overview.propTypes = {
  jobId: PropTypes.number,
  t: PropTypes.func, // HOC
  data: PropTypes.object,
  history: PropTypes.object,
  calculations: PropTypes.object
};

export default Overview;