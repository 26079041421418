import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TEXT_FILTER, FACETS_FILTER } from './jobTableUtils';
import TextFilter from './filters/textFilter';
import FacetsFilter from './filters/facetsFilter';

const JobTableFilters = ({ columns, facets }) => {
  const { t } = useTranslation();

  const renderFilter = (column) => {
    const columnFacets = facets && facets[column.accessorKey];
    switch (column.meta.filter) {
      case TEXT_FILTER:
        return (<TextFilter column_id={column.accessorKey}/>)
      case FACETS_FILTER:
        return (<FacetsFilter column_id={column.accessorKey} facets={columnFacets}/>)
    }
  };

  return (
    <div>
      <h2>{t('jobTable.filters_header')}</h2>
      {columns.map(column => {
        if (column.meta?.filter && column.accessorKey)
          return (
            <div key={column.accessorKey}>
              <h3>{t(`jobTable.columns.${column.accessorKey}`)}</h3>
              { renderFilter(column) }
            </div>
          )
      })}
    </div>
  )
};

JobTableFilters.propTypes = {
  columns: PropTypes.array,
  facets: PropTypes.object,
};

export default JobTableFilters;
