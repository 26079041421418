/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';

export const SectionTitleWidget = () => {
  return (
    <div>
      <hr/>
    </div>
  );
};

export default SectionTitleWidget;
