import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { 
  ButtonsContainer, 
  CardContainer, 
  IconContainer, 
  NewTask, 
  SubmitAgain, 
  RecentlyTemplate, 
  RecentlyTemplateTime, 
  TemplateContainer, 
  TemplateMain, 
  TemplateTitle, 
  TemplatesContainer
} from '../../styles/styled-components/newTemplates';
import TemplateIcon from '../../../assets/img/templates/template-icon';
import { ClockIcon } from '../../../assets/img/templates/icons.js';
import { getDefaultGrant } from '../../utils/defaultGrant';
import CustomCardButton from '../customCardButton.jsx';

const RecentlyTemplates = ({ grantsStatus, recetlyTemplates, setActiveUrl }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory(); 

  const grantDefaultQuery = getDefaultGrant(location);

  const [ isActive, setIsActive ] = useState(true);

  const formatTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    const currentDate = new Date();
    const difference = currentDate - dateObject;

    const isSameDay = currentDate.getFullYear() === dateObject.getFullYear() &&
      currentDate.getMonth() === dateObject.getMonth() &&
      currentDate.getDate() === dateObject.getDate();

    const seconds  = Math.floor(difference / (1000));
    const minutes = Math.floor(difference / (1000 * 60));

    if (isSameDay) {
      if (minutes < 1) {
        return `${seconds} ${t('new_templates.second')} ${t('new_templates.ago')}`;
      }
  
      else if (minutes < 10) {
        return `${minutes} min ${t('new_templates.ago')}`;
      }
  
      else {
        return dateObject.toLocaleTimeString();
      }
    }
    
    return dateObject.toLocaleDateString();
  };

  const showTemplateDetails = (url) => {
    setActiveUrl(url);
    history.push(`/ui/templates?details${grantDefaultQuery && `&grant_default=${grantDefaultQuery}`}`);
  };

  if (!recetlyTemplates || recetlyTemplates?.length === 0)
    return null;

  return (
    <CardContainer isActive={isActive} Recently>
      <CustomCardButton
        recently
        icon={ClockIcon}
        id="checkRecentlyTemplates"
        alt="AllTRecentlyTemplatesIcon"
        isActive={isActive}
        setIsActive={setIsActive}
        title={t('new_templates.recently_used_templates')}
      />
      <Collapse isOpen={isActive}>
        <TemplatesContainer>
          { recetlyTemplates.map((template, index) => 
            <TemplateContainer key={index} Recently onClick={() => showTemplateDetails(template?.source)}>
              <IconContainer>
                {template?.icon_url
                  ? <img src={template.icon_url} alt="template icon" className="mw-50 mh-75"/> 
                  : <TemplateIcon alt="" className="mw-50 mh-75"/>
                }  
              </IconContainer>
              <TemplateMain>
                <TemplateTitle>{template?.name}</TemplateTitle>
                <RecentlyTemplate>
                  <img src={ClockIcon} alt="ClockIcon" id={`clockIcon${index}`}/>
                  <RecentlyTemplateTime>{formatTimestamp(template?.last_submit_time)}</RecentlyTemplateTime>
                </RecentlyTemplate>
              </TemplateMain>  
              <ButtonsContainer>
                <NewTask
                  id={`recentlyTemplate-button-newtask-${index}`}
                  disabled={!grantsStatus}
                  onClick={(e) => {e.stopPropagation(); if (!grantsStatus) e.preventDefault();}}
                  to={`/ui/submit?templateUrl=${template?.source}${grantDefaultQuery 
                    ? `&grant_default=${grantDefaultQuery}&usePreviursVaraibles=false` 
                    : '&usePreviursVaraibles=false'}`}      
                  style={{ position: "static" }}
                >
                  {t('new_templates.new_task')}
                </NewTask>
                { template?.attributes?.resubmit !== false &&
                  <SubmitAgain
                    id={`recentlyTemplate-button-orderagain-${index}`}
                    disabled={!grantsStatus}
                    onClick={(e) => {e.stopPropagation(); if (!grantsStatus) e.preventDefault();}}
                    to={`/ui/submit?templateUrl=${template?.source}${grantDefaultQuery 
                      ? `&grant_default=${grantDefaultQuery}&usePreviursVaraibles=true` 
                      : '&usePreviursVaraibles=true'}`
                    }      
                  >
                    {t('new_templates.order_again')}
                  </SubmitAgain>
                }
                {!grantsStatus && (
                  <>
                    <UncontrolledTooltip placement="bottom" target={`recentlyTemplate-button-newtask-${index}`}>
                      {t('template_view.no_grant_info')}
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="bottom" target={`recentlyTemplate-button-orderagain-${index}`}>
                      {t('template_view.no_grant_info')}
                    </UncontrolledTooltip>
                  </>
                )}
              </ButtonsContainer>
            </TemplateContainer>
          ) 
          }
        </TemplatesContainer>
      </Collapse>
    </CardContainer>
  );
};

export default RecentlyTemplates;

RecentlyTemplates.propTypes = {
  grants: PropTypes.number,
  grantsStatus: PropTypes.number,
  recetlyTemplates: PropTypes.array,
  setActiveUrl: PropTypes.func
};