import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import { IframeButton, IframeContainer } from '../../styles/styled-components/jobs/Monitoring';
import { FullScreen, MinScreen } from '../../../assets/img/task/icons';
import { CardContainer, Checkbox, CollapseText, HorizontalLine, Line, StyledButton } from '../../styles/styled-components/newTemplates';
import { ButtonCotainer, CustomButton } from '../../styles/styled-components/jobs/Results';

const IframeDisplay = ({
  panelUrl,
  index,
  isActive,
  isFullScreen,
  setActivePanels,
  setFullScreenIndex,
  title
}) => {

  const handleButtonClick = (event) => {
    if (!event.target.closest(`#checkIfream-${index}`) && !event.target.closest(`label[for="checkIfream-${index}"]`)) {
      setActivePanels(prev => {
        const newActivePanels = [ ...prev ];
        newActivePanels[index] = true;
        return newActivePanels;
      });
    }
  };

  const toggleFullScreen = (index, e) => {
    e.stopPropagation();
    setFullScreenIndex(prev => prev === index ? null : index);
  };

  const handleCheckboxChange = (index, e) => {
    e.stopPropagation();
    setActivePanels(prev => {
      const newActivePanels = [ ...prev ];
      newActivePanels[index] = !newActivePanels[index];
      return newActivePanels;
    });
  };

  return (
    <CardContainer isActive={isActive}>
      <CustomButton onClick={handleButtonClick} isActive={isActive} title={title}>
        <CollapseText isActive={isActive}>{title}</CollapseText>
        <ButtonCotainer>
          <IframeButton onClick={(e) => toggleFullScreen(index, e)}>
            <FullScreen alt="Full Screen" />
          </IframeButton>
          <StyledButton htmlFor={`checkIfream-${index}`} isActive={isActive}>
            <Checkbox type="checkbox" id={`checkIfream-${index}`} checked={isActive} onChange={(e) => handleCheckboxChange(index, e)} />
            <Line type="line1" isActive={isActive} />
            <Line type="line2" isActive={isActive} />
          </StyledButton>
        </ButtonCotainer>
      </CustomButton>
      <Collapse isOpen={isActive || isFullScreen}>
        <HorizontalLine />
        <IframeContainer isFullScreen={isFullScreen}>
          {isFullScreen &&
            <IframeButton onClick={(e) => toggleFullScreen(index, e)} fullScreen>
              <MinScreen alt="Minimize Screen" />
            </IframeButton>
          }
          {(isActive || isFullScreen) && <iframe title={`IFrame-${index}`} src={panelUrl} />}
        </IframeContainer>
      </Collapse>
    </CardContainer>
  );
};

export default IframeDisplay;

IframeDisplay.propTypes = {
  panelUrl: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  setActivePanels: PropTypes.func,
  setFullScreenIndex: PropTypes.func,
  title: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string, ])
};