import React from 'react';
import PropTypes from 'prop-types';

import { 
  Checkbox, 
  CollapseText, 
  Line, 
  StyledButton, 
  Img
} from '../styles/styled-components/newTemplates';
import { CustomButton, CustomButtonTittle } from '../styles/styled-components/jobs/Results';

const CustomCardButton = ({ alt, icon, id, isActive, recently = false, setIsActive, title }) => {
  const handleButtonClick = (event) => {
    if (!event.target.closest(`#${id}`) && !event.target.closest(`label[for="${id}"]`)) {
      setIsActive(true);
    }
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <CustomButton Recently={recently} onClick={handleButtonClick} isActive={isActive} title>
      <CustomButtonTittle>
        {icon && <Img src={icon} alt={alt} />}
        <CollapseText isActive={isActive}>{title}</CollapseText>
      </CustomButtonTittle>
      <StyledButton htmlFor={id} isActive={isActive}>
        <Checkbox type="checkbox" id={id} isActive={isActive} onChange={handleCheckboxChange} />
        <Line type="line1" isActive={isActive} />
        <Line type="line2" isActive={isActive} />
      </StyledButton>
    </CustomButton>
  );
};

export default CustomCardButton;

CustomCardButton.propTypes = {
  alt: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  recently: PropTypes.bool,
  setIsActive: PropTypes.func.isRequired,
  title: PropTypes.string
};