import React from 'react';
import PropTypes from 'prop-types';

import { Spinner, SpinnerContainer } from '../../qcg-ui/styles/styled-components/Loading';

const Loading = ({ top=false }) => {
  return (
    <SpinnerContainer top={top}>
      <Spinner />
    </SpinnerContainer>
  );
};

export default Loading;

Loading.propTypes = {
  top: PropTypes.bool
};