/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import axiosInstance from '@axios';
import FileInput from '../fileSelectorProviders/fileInput';
import InputIframeModal from '../fileSelectorProviders/inputIframeModal';
import DirectUpload from '../fileSelectorProviders/directUpload';
import { StyledRow } from '@ibis-ui/fileField/fileField.styled';
import { t } from 'i18next';

export const CustomFileWidget = (props) => {
  const [ currentProvider, setCurrentProvider ] = useState(null);
  const [ selectedProviderDisplay, setSelectedProviderDisplay ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ providers, setProviders ] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance({
      url: "/config/fileproviders",
      method: "get",
    })
      .then((response) => {
        setProviders(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const providerOnClose = () => {
    setCurrentProvider(null);
  };

  const submitValue = (value) => {
    props.onChange(value);
    providerOnClose();
  };

  const parseProvider = (provider) => {
    const submitValueAndSetProvider = (value) => {
      setSelectedProviderDisplay({
        name: provider.label,
        icon: provider.icon,
      });
      submitValue(value);
    };
    return {
      id: provider.id,
      icon: provider.icon,
      name: provider.label || provider.id,
      type: provider.type,
      component:
        provider.type === "custom" ? (
          <FileInput
            submitValue={submitValueAndSetProvider}
            onClose={providerOnClose}
          />
        ) : provider.type === "url" ? (
          <InputIframeModal
            submitValue={submitValueAndSetProvider}
            onClose={providerOnClose}
            providerData={provider}
            title={provider.label || provider.id}
          />
        ) : provider.type === "direct-upload" ? (
          <DirectUpload
            submitValue={submitValueAndSetProvider}
            onClose={providerOnClose}
            url={provider.url}
          />
        ) : null,
    };
  };

  const fileSelectorProviders =
  providers?.filter(
    (provider) => provider.type !== "direct-upload"
  ).map((provider) => parseProvider(provider));

  const directUpload = providers.find(
    (provider) => provider.type === "direct-upload"
  );

  const directUploadProvider = directUpload && parseProvider(directUpload);

  const renderOption = (provider, index) => {
    return (
      <Button
        key={index}
        onClick={() => {
          setCurrentProvider(provider.id);
        }}
        className="providers-tile"
      >
        <div className="w-100 align-self-center d-flex flex-column">
          {provider.icon && (
            <Row className="m-0 justify-content-center w-100 mb-2">
              {provider.icon}
            </Row>
          )}
          <Row className="capitalize m-0 justify-content-center w-100">
            {provider.name}
          </Row>
        </div>
      </Button>
    );
  };

  const renderProvider = () => {
    const provider = fileSelectorProviders.find(
      (element) => element.id === currentProvider
    );
    return provider ? provider.component : null;
  };

  return (
    <Container className="providers-container">
      <Row className="pb-3 dropzone-text">{t("dropzone")}:</Row>
      {isLoading && <Spinner/>}
      <Row>
        {directUploadProvider && (
          <Col sm={7}>{directUploadProvider.component}</Col>
        )}

        <Col sm={5}>
          <div className="providers-grid">
            {fileSelectorProviders
              .filter((provider) => provider.type !== "direct-upload")
              .map((provider, index) => renderOption(provider, index))}
          </div>
        </Col>
      </Row>

      {props.value && (
        <div className="mt-3">
          {selectedProviderDisplay && (
            <div className="dropzone-text">
              {selectedProviderDisplay.icon}
              {selectedProviderDisplay.name}
            </div>
          )}
          <StyledRow className="mt-2">
            <span className="pl-3">
              <b>{props.value}</b>
            </span>
          </StyledRow>
        </div>
      )}
      {renderProvider()}
    </Container>
  );
};

CustomFileWidget.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CustomFileWidget;
