/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import axiosInstance from '../../main/utils/axios/axiosInstance';

const namespace = '@QCG_TEMPLATES/';

export const LOAD_TEMPLATE = namespace + 'LOAD_TEMPLATE';
export const TEMPLATES_LOADING = namespace + 'TEMPLATES_LOADING';
export const TEMPLATES_LOADED = namespace + 'TEMPLATES_LOADED';

export const loadTemplates = () => dispatch => {
  //TODO: errors handing and empty lists notifying
  dispatch({ type: TEMPLATES_LOADING });
  axiosInstance({
    url: "/templates/",
    method: "get"
  })
    .then(response => {
      const templates = response.data && response.data.templates;
  
      if (templates && Array.isArray(templates) && templates.length > 0) {
        //template list is not empty
        templates.forEach((template, index) => {
          if (template.name) {
            dispatch({
              type: LOAD_TEMPLATE,
              payload: {
                id: template.source,
                template,
              }
            });
          }
        });
      }
      dispatch({ type: TEMPLATES_LOADED });
    });
};
