import styled, { css } from 'styled-components';

import { theme } from '../../theme';

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NormalCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${theme.colors.grey[700]};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  gap: 10px;
`;

export const ButtonCotainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  background-color: ${theme.colors.white};
  border: none;
  color: ${theme.colors.primary[700]};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  line-height: 18px;
  letter-spacing: 0.36px;
  padding: 0 8px;

  ${({ Recently }) => Recently && css`
    background-color: ${theme.colors.primary["0"]};
  `};

  ${({ isActive }) => isActive && css`
    cursor: default !important;
  `};

  ${({ title }) => !title && css`
      justify-content: flex-end;
  `}
`;

export const CustomButtonTittle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Arrow = styled.div`
  border: solid ${theme.colors.grey[700]};
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-135deg);
  text-align: center;

  ${({ active }) => active && css`
    animation: rotateButton 0.5s ease forwards;
  ` };

  @keyframes rotateButton {
    0% {
      transform: rotate(-135deg);
    }
    100% {
      transform: rotate(-315deg);
    }
}
`;