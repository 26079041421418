/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Row, Col, Card } from 'reactstrap';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import axios from '@axios';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import { getDefaultGrant } from '../../utils/defaultGrant';
import { __env } from '../../../envloader';

import TemplateIcon from '../../../assets/img/templates/template-icon';
import noTemplateIcon from '../../../assets/img/templates/no-template-icon.svg';
import { FaChevronLeft } from 'react-icons/fa';

@withTranslation()
@connect(
  state => ({
    templates: state.templates.get('data'),
  })
)
@withSendRequest
class TemplateDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      grants: []
    };
  }

  componentDidMount() {
    if (__env.BLOCK_SUBMIT_FOR_NO_GRANTS) {
      axios.get('user/')
        .then(response => this.setState({ grants: response.data['user-info'].grants }))
        .catch(() => this.setState({ grants: [] }));
    }
  }

  render() {
    const { t } = this.props;
    const templateData = this.props.templates.get(this.props.templateUrl);
    const languages = templateData && templateData.get('description').keySeq().toJS().join(', ');
    const shortLngCode = getCurrentLanguageShortCode();
    const grantDefaultQuery = getDefaultGrant(this.props.location);
    const additionalQuery = (grantDefaultQuery !== undefined) ? `?grant_default=${grantDefaultQuery}` : '';
    
    if (templateData)
      return (
        <div className="template-details-container">
          <div className="mx-3">
            <Link to={`/ui/templates${additionalQuery}`} className="d-flex align-items-center mt-3 mb-3 submit-back" data-testid='submit-back-href'>
              <FaChevronLeft />
              <span className="ml-2">{this.props.t('submit_view.back_to_templates')}</span>
            </Link>
            <h1>{templateData.get('name')}</h1>
            <p className="b4-text">{templateData.getIn([ 'description', shortLngCode ])}</p>
            <Card className="py-5 my-4">
              <Row>
                <Col sm="3" className="px-4 d-flex flex-row justify-content-center align-items-center">
                  {templateData.get('icon_url') 
                    ? <img src={templateData.get('icon_url')} alt="" className="mw-50 mh-75"/> 
                    : <TemplateIcon alt="" className="mw-50 mh-75"/>}
                </Col>
                <Col sm="9" className="pl-1 d-flex flex-column justify-content-center align-items-start">
                  {
                    templateData.get('refs') &&
                      <div className="doc-refs">
                        <p className="b3-text">{t('template_view.docs')}</p>
                        <ul>
                          {
                            templateData.get('refs').map(url =>
                              <li className="my-2" key={url}>
                                <a href={url} target="_blank" rel="noreferrer">{url}</a>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                  }
                  <Button
                    tag={Link}
                    to={`/ui/submit?templateUrl=${this.props.templateUrl}${grantDefaultQuery 
                      ? `&grant_default=${grantDefaultQuery}` 
                      : ''}`
                    }
                    color="primary"
                    className="my-3"
                    disabled={__env.BLOCK_SUBMIT_FOR_NO_GRANTS ? this.state.grants.length === 0 : false}
                  >
                    {t('template_view.use_to_submit')}
                  </Button>
                  {
                    (__env.BLOCK_SUBMIT_FOR_NO_GRANTS && this.state.grants.length === 0) &&
                    <p className="small-text text-danger">{t('template_view.no_grant_info')}</p>
                  }
                </Col>
              </Row>
            </Card>
            <div className="small-text">
              <p className="mb-0">{t('template_view.version')} {templateData.get('version')}</p>
              <p className="mb-0">{t('template_view.languages')} {languages}</p>
              <p className="mb-0">
                {t('template_view.url')}
                <a href={this.props.templateUrl} target="_blank" rel="noreferrer">{this.props.templateUrl}</a>
              </p>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="template-details-container">
          <h1>{t('template_view.no_template_title')}</h1>
          <p className="b4-text">{t('template_view.no_template_justification')}</p>
          <Card className="py-5 my-4 no-template-card">
            <Row>
              <Col sm="3" className="px-4 d-flex flex-row justify-content-center align-items-center">
                <img src={noTemplateIcon} alt="" className="mw-50 mh-75"/>
              </Col>
              <Col sm="9" className="pl-1 d-flex flex-column justify-content-center align-items-start">
                <div>
                  <h2>{t('template_view.no_template_card_title')}</h2>
                  <div className="justification">
                    <Trans i18nKey='template_view.no_template_card_justification'>
                      first line <br/>second line<span className="text-dark">action</span> rest
                    </Trans>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      );
  }
}

TemplateDetails.propTypes = {
  t: PropTypes.func, //HOC
  templates: PropTypes.object, //HOC
  sendRequest: PropTypes.func, //HOC
  templateUrl: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(TemplateDetails);
