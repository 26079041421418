import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import { Hr, NormalColumn } from '../styles/styled-components/GlobalStyles';
import { CollapseIcon } from '../../assets/img/sidebar/icons';
import QCGLOGO from '../../assets/img/landing/qcg.png';
import { navItems } from '../../main/constants/navItems';
import { 
  CollapseWrapper, 
  CollapseWrapperItem, 
  FixedSidebar, 
  Menu, 
  MenuEl, 
  LogoContainer, 
  ElText
} from '../styles/styled-components/GlobalSidebar';
import { theme } from '../styles/theme';
import Loading from '../../main/components/loading';
import axiosInstance from './../../main/utils/axios/axiosInstance';

const GloabalSidebar = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();

  const [ isActive, setIsActive ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isOpenTooltipId, setIsOpenTooltipId ] = useState(null);
  const [ isTextOverflow, setIsTextOverflow ] = useState(false);
  const [ updateNavItems, setUpdateNavItems ] = useState();

  const toggle = (id) => setIsOpenTooltipId(isOpenTooltipId === id ? null : id);

  const checkTextOverflow = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      setIsTextOverflow(element.offsetWidth < element.scrollWidth);
    }
  };

  const getProviders = () => {
    setIsLoading(true);
    axiosInstance.get("/config/fileproviders")
      .then((response) => {
        const updateNavItems = navItems.map(item => {
          if (item.path === "Folder") {
            return { ...item, url: response.data.find(ele => ele.id === 'ibis-cluster-home')?.url };
          }
          return item;
        }
        );
        setUpdateNavItems(updateNavItems);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => getProviders(), []);

  return (
    <div style={{ position: "relative" }}>
      <FixedSidebar isOpen={isOpen}>
        <NormalColumn fullHeight>
          <LogoContainer isOpen={isOpen}>
            {theme.serviceLogo ? (
              <img className="w-100 p-2" src={theme.serviceLogo} alt="logo" />
            ) : (
              <img src={QCGLOGO} alt="logo-QCG"/>
            )}
          </LogoContainer>     
          <Hr style={{ marginBottom: "10px" }} isOpen={isOpen}/>
          <Menu>
            {isLoading 
              ? <Loading top/>
              : updateNavItems?.map((item) => (
                <MenuEl
                  key={item.label}
                  isOpen={isOpen}
                  onClick={() => setIsActive(item.label)}
                  isActive={item.label === isActive}
                  disabled={item.label === "nav_items.settings"}
                  href={item?.url}
                  onMouseEnter={() => {
                    checkTextOverflow(`ElText-${item.path}`);
                    toggle(`ElText-${item.path}`);
                  }}
                  onMouseLeave={() => toggle(null)}
                  target={item?.target || "_self"}
                >
                  <item.icon alt={item.path} id={`ElIcon-${item.path}`}/>
                  <ElText id={`ElText-${item.path}`} >
                    {t(item.label)}
                  </ElText>
                  {isTextOverflow && (
                    <Tooltip 
                      isOpen={isOpenTooltipId === `ElText-${item.path}`}
                      toggle={() => toggle(null)}
                      target={isOpen ? `ElText-${item.path}` : `ElIcon-${item.path}`}
                      placement={isOpen ? `top` : `right`}
                    >
                      {t(item.label)}
                    </Tooltip>
                  )}
                </MenuEl>
              ))}
          </Menu>
          <CollapseWrapper>
            <CollapseWrapperItem isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
              <img src={CollapseIcon} alt='CollapseIcon'/>
            </CollapseWrapperItem>
          </CollapseWrapper>
        </NormalColumn>
      </FixedSidebar>
    </div>
  );
};

export default GloabalSidebar;

GloabalSidebar.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
};