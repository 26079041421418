import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { fromJS, Map, List } from 'immutable';
import PropTypes from 'prop-types';

import axiosInstance from '../../../main/utils/axios/axiosInstance';
import Overview from './overview.jsx';
import Monitoring from './monitoring.jsx';
import Results from './results.jsx';
import TaskState from '../../components/taskState';
import RefreshFeature from '../../components/refreshFeature';
import { AngleRight, Errors, SubmitAgainIcon, TaskIcon, WarningIcon, View, VisibleHuge, Result } from '../../../assets/img/task/icons.js';
import TaskCanceler from '../tasks/taskCanceler';
import TaskRemover from '../tasks/taskRemover';
import {
  ContainerJob, 
  Breadcrumbs, 
  Error,
  ErrorBody,
  ErrorTitle,
  ErrorText,
  Header, 
  JobName,
  Up, 
  UpButton, 
  ContainerImg, 
  UpText, 
  Link, 
  HeaderText, 
  Down, 
  ContainerButtons, 
  Tabs, 
  TabsEl 
} from '../../styles/styled-components/jobs/JobDetails';
import { __env } from '../../../envloader';
import ShowFileButton from '../../components/showFileButton';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import { SubmitAgain } from '../../styles/styled-components/newTemplates.js';

@withTranslation()
export default class JobDetails extends Component {
  jobId = this.props.match.params.id;
  state = {
    data: Map(),
    history: List(),
    activeTab: 'overview',
    storages: {},
    isModalOpen: false,
    calculations: {},
    shortLngCode: getCurrentLanguageShortCode(),
    graphes: List(),
    isLoadingGraphes: false
  };

  decomposeTaskErrors(results) {
    if (!results || !results.length) return null;
    for (let i=0; i<results.length-1; i++) {
      //cut all old errors
      results[i]['error'] = results[i+1]['errors'] ? results[i]['errors'].slice(0,-(results[i+1]['errors'].length)) : results[i]['errors'];
      //cut '. ' if any
      results[i]['error'] = (results[i]['error'] && results[i]['error'].endsWith('. ')) ? results[i]['error'].slice(0,-2): results[i]['error'];
    }
    results[results.length-1]['error']=results[results.length-1]['errors'];

    return results;
  };

  loadTaskData = () => {
    axiosInstance({
      url: '/jobs/' + this.jobId + '/',
      method: "get",
    })
      .then(response => {
        this.setState({ data: fromJS(response.data) });

        if (response.data?.rt_attributes?.monitoring_enabled === "ENABLED" && !this.state.graphes?.panels) {
          this.setState({ isLoadingGraphes: true });

          axiosInstance({
            url: `/jobs/${this.jobId}/dashboard/`,
            method: "POST"
          })
            .then( response => 
              this.setState({ 
                graphes: response.data,
                isLoadingGraphes: false
              })
            );
        }
      });
  };

  loadTaskHistory = () => {
    axiosInstance({
      url: '/jobs/' + this.jobId + '/events/',
      method: "get",
    })
      .then(response => {
        this.setState({ history: fromJS(this.decomposeTaskErrors(response.data.results)) });
      });
  };

  loadTaskStorages = () => {
    axiosInstance({
      url: '/storages/',
      method: "get",
    })
      .then(response => {
        this.setState({ storages: response.data });
      });
  };

  getCalculation = () => {
    axiosInstance({
      url: `/jobs/${this.jobId}/refs/?lang=${this.state.shortLngCode}`,
      method: "get",
    })
      .then(response => 
        this.setState({ calculations: response.data?.Computations })        
      );
  };

  componentDidMount() {
    this.loadTaskData(); 
    this.loadTaskHistory();
    this.loadTaskStorages();
    this.getCalculation();
  };

  chooseTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  refresh = () => {
    this.loadTaskData();
  };

  processUrl = (url, replaceArray = []) => 
    replaceArray.reduce((accUrl, item) => accUrl.replace(`{${item.tag}}`, item.value), url);

  isTabDisabled(tabName) {
    return tabName === "results" && ![ 'FINISHED', 'FAILED', 'CANCELED' ].includes(this.state.data.get('major_state'));
  }
  
  handleTabClick(tabName) {
    if (!this.isTabDisabled(tabName)) {
      this.chooseTab(tabName);
    }
  }

  renderComponent = (tab) => {
    const { data, history, calculations, graphes, isLoadingGraphes } = this.state;
    switch (tab) {
    case 'overview':
      return <Overview data={data} jobId={this.jobId} history={history} calculations={calculations}/>;
    case 'monitoring':
      return <Monitoring
        url={data?.getIn([ 'rt_attributes', 'work_dir_ui_url' ])} 
        title={this.props.t("work_dir_preview")}
        graphes={graphes}
        isLoadingGraphes={isLoadingGraphes}
      />;
    case 'results':
      return <Results 
        url={data?.getIn([ 'rt_attributes', 'result_gui' ])}
        storages={this.state.storages}
        state={data.get('state')}
        files={data.get('files').toJS() || []}
      />;
    default:
      break;
    }
  };

  render () {
    const tabs = [
      [ 'overview', View ],
      [ 'monitoring', VisibleHuge ],
      [ 'results', Result ]
    ];
    const errors = this.state.data.get('errors');

    return (
      <ContainerJob>
        <Breadcrumbs>
          <Link to={'/ui/tasks/'}>{this.props.t('task.task_list')}</Link>
          <img src={AngleRight} alt="icon"/>
          <JobName>{this.props.t('task.task')} {this.jobId}</JobName>
        </Breadcrumbs>
        <Header>
          <Up>
            <UpText>
              <HeaderText>{this.state.data.get('note')}</HeaderText>
              (ID:
              <JobName style={{ paddingLeft: "6px" }}>
                {this.jobId}
              </JobName>
              <img src={TaskIcon} alt="icon"/>
              )
            </UpText>
            <div style={{ display: 'flex', gap: '12px' }}>
              { __env.SUPPORT_URL_PATTERN_JOB && 
                <UpButton href={ this.processUrl( __env.SUPPORT_URL_PATTERN_JOB, [ { tag: 'JOB_ID', value: this.jobId } ] ) }>
                  <ContainerImg>
                    <img src={WarningIcon} alt="icon"/>
                  </ContainerImg>
                  {this.props.t('task.report_problem')}
                </UpButton>  
              }
              {this.state.data?.getIn([ 'rt_attributes', 'non_resubmitable' ]) !== false && 
                <SubmitAgain 
                  to={`/ui/submit?templateUrl=${this.state.data?.get('template_url')}&resubmitJobId=${this.jobId}`}
                  style={{ gap: '8px' }}
                >
                  <img src={SubmitAgainIcon} alt="Submit Again Icon"/>
                  {this.props.t('new_templates.order_again')}
                </SubmitAgain>
              }
            </div>
          </Up>
          <Down>
            <RefreshFeature
              minValue={10}
              maxValue={100}
              default={30}
              fetchData={() => { this.loadTaskData(); this.loadTaskHistory(); this.loadTaskStorages(); this.getCalculation(); }}
            />
            <ContainerButtons>
              <TaskState state={this.state.data.get('major_state')}/>
              <TaskCanceler 
                withoutText
                taskId={this.jobId}
                refresh={this.refresh}
                currentState={this.state.data.get('major_state')}
              />
              <TaskRemover 
                withoutText
                taskId={this.jobId}
                refresh={this.refresh}
              />
              <ShowFileButton 
                fileContent={this.state.data}
                fileName={this.props.t('details_json')}
                withoutText 
              />
            </ContainerButtons>
          </Down>
        </Header>
        { errors &&
          <Error>
            <img src={Errors} alt="job's error"/>
            <ErrorBody>
              <ErrorTitle>{this.props.t('task.error')}</ErrorTitle>
              <ErrorText>{errors}</ErrorText>
            </ErrorBody>
          </Error>
        }
        <Tabs> 
          { tabs.map((tab, index) => 
            <TabsEl
              active={this.state.activeTab === tab[0]}
              key={index}
              onClick={() => this.handleTabClick(tab[0])}
              disabled={this.isTabDisabled(tab[0])}
            >
              <img src={tab[1]} alt={tab[0]}/>
              {this.props.t(`task.${tab[0]}`)}
            </TabsEl>     
          )}
        </Tabs>
        {this.renderComponent(this.state.activeTab)}
      </ContainerJob>
    );
  }
}

JobDetails.propTypes = {
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  match: PropTypes.object.isRequired //HOC
};